module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/zeit/2c367f11/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.30","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]},{"resolve":"/zeit/2c367f11/node_modules/gatsby-remark-copy-linked-files","id":"80533a5b-ad2d-50ff-af51-726674869458","name":"gatsby-remark-copy-linked-files","version":"2.1.29","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"remarkPlugins":[null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":["gatsby-remark-relative-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":754}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"files"}},"/zeit/2c367f11/node_modules/@commercetools-docs/gatsby-theme-docs/src/plugins/remark-rewrite-relative-links.js"]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"AppKit | commercetools docs","name":"AppKit | commercetools docs","description":"Develop applications for the Merchant Center","start_url":"/app-kit/","background_color":"#FFFFFF","theme_color":"#078CDF","display":"standalone","icon":"/zeit/2c367f11/node_modules/@commercetools-docs/gatsby-theme-docs/src/icons/logo.svg"},
    },{
      plugin: require('../../node_modules/@commercetools-docs/gatsby-theme-docs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
